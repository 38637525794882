import { React } from "react";
import {
  bgimage,
} from "../../Assets";

const Home = () => {

  return (
    <div >
      {/* Landing */}
      <section className="lg:mt-12 lg:mx-24 mt-12 max-w-screen-xl pb-12 px-4 items-center justify-center lg:flex md:px-8 ">
        <div className="space-y-4 flex-1 sm:text-center lg:text-left">
          <h1 className="text-white font-bold text-6xl xl:text-8xl font-press">
            Doggi
          </h1>

          <p className="text-gray-300 text-2xl max-w-xl leading-relaxed  sm:mx-auto  lg:ml-0 ">
          The first NFT tokenized memecoin building on @arbitrum!

          </p>
          {/* <p className="text-orange-200">
            Trading is Live on <a className="underline" href="https://syncswap.xyz/linea">SyncSwap</a> <br/>
            CA: 0x6dae8434644dce01d6d4b200c56c2c078bf7e639 <br/>
          
          </p> */}


        </div>
        <div className="flex-1 text-center mt-7 lg:mt-0 lg:ml-3">
          <img
            src={bgimage}
            className=" mx-auto sm:w-6/12  lg:w-2/3 md:w-4/12"
            alt="doggi"
          />
        </div>
      </section>

      {/* Claim */}
      <section className=" m-4 p-4 " id="airdrop">
        <div className="max-w-screen-xl mx-auto text-slate-50  p-4 lg:w-6/12 rounded-2xl text-left bg-purple-500 ">
          <p className="text-white-500 text-2xl text-center max-w-xl leading-relaxed sm:mx-auto ">
            Doggi is more than just a memecoin; its the first NFT tokenized memecoin building on @arbitrum. Born from the spirit of fun and community that memecoins are known for, Doggi takes it to the next level by harnessing the power of the Arbitrum Mainnet.

          </p>
        </div>
      </section>

      {/* About */}
      <section className="lg:mt-12 lg:mx-24 mt-12 py-14 pb-12 px-4 md:px-8 border" id="about">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <div className="relative max-w-xl text-white mx-auto sm:text-center">
            <h2 className=" text-4xl font-bold sm:text-6xl ">
              What Makes Doggi Special?

            </h2>

            <p className="text-white-500 text-2xl text-center max-w-xl leading-relaxed sm:mx-auto pt-8">
              Built on arbitrum, Doggi leverages the exceptional scalability, security, and privacy that L2 offers. This allows for seamless transactions while enjoying the thrilling and inclusive culture the Doggi community fosters.


            </p>
          </div>

          <div className="mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-4 ">

          </div>
        </div>
      </section>

      {/*Get Started*/}
      <section
        className="lg:mt-12 lg:mx-24 mt-12 py-14 pb-12 px-4 md:px-8 border"
        id="start"
      >
        <div className="max-w-screen-xl mx-auto px-4 text-white md:px-8 ">
          <div className="relative max-w-xl mx-auto sm:text-center">
            <h2 className="text-4xl font-bold sm:text-6xl ">
              How to Get Started with Doggi

            </h2>
          </div>

          <p className="text-white-500 text-2xl text-center max-w-xl leading-relaxed sm:mx-auto pt-8">
            Doggi isn't just about being a part of the community; it's about making your mark in the crypto world. We're excited to have you on this journey with us. Stay tuned for information on how and where to buy, sell, and trade doggi.
            <br/>

            <button className="bg-gray-100 p-2">
            <a target="_blank" className="text-blue-500" href="https://opensea.io/collection/doggi-15/">
          <div className="flex justify-center"> <span>Buy Doggi NFT from Opensea </span>   <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
<circle cx="24" cy="24" r="20" fill="#1565c0"></circle><path fill="#fff" d="M12,27h7c0,1.491,0.515,2,2,2h7c0.84,0,2.157-0.287,2.707-1.316l6.178-1.798L37,27.564	c-1.074,0.407-1.979,0.648-3.612,3.327C31.138,34.218,30.783,34,28.058,34H19C14.92,34,12,31,12,27L12,27z"></path><path fill="#fff" d="M19.172,16.034C22.759,20.517,19,25,19,25h-6L19.172,16.034z"></path><path fill="#fff" d="M21,27h7c0,0,8.207-5.448-1.655-11.724C23.655,14.379,20,14,20,14S26,21,21,27z"></path><path fill="#fff" d="M25,12L25,12c-0.552,0-1,0.448-1,1v17h2V13C26,12.448,25.552,12,25,12z"></path>
</svg></div> 
                                    </a>
            
            </button>
          </p>
        </div>
      </section>

      {/*Vision*/}
      <section
        className="lg:mt-12 lg:mx-24 mt-12 py-14 pb-12 px-4 md:px-8 border"
        id="vision"
      >
        <div className="max-w-screen-xl mx-auto px-4 text-white md:px-8 ">
          <div className="relative max-w-xl mx-auto sm:text-center">
            <h2 className="text-4xl font-bold sm:text-6xl ">
              Our Vision


            </h2>
          </div>

          <p className="text-white-500 text-2xl text-center max-w-xl leading-relaxed sm:mx-auto pt-8">
             We're not just creating a memecoin; we're building a movement. Our roadmap outlines our ambitious plans for LineaDoge's future development. We aim to innovate, excite, and unite under the banner of Doggi.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Home;
