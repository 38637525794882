import React from 'react'
import { Logo } from '../../Assets'


const current = new Date();
const date = current.getFullYear();

const footerNavs = [
    {
        href: '../#about',
        name: 'About  '
    },
    {
        href: '../#start',
        name: 'Get Started '
    },
    {
        href: '../#vision',
        name: 'Vision'
    },
]

const Footer = () => {
    return (
        <div>
             {/* <p className="text-orange-200 py-5">
            Trade on <a className="underline" href="https://syncswap.xyz/linea">SyncSwap</a> <br/>
            
             Live Chart : <a className="underline" href="https://www.geckoterminal.com/linea/pools/0x98446ac9f1aeec7154ccfbe15564dc31cb25bfe5">https://www.geckoterminal.com/linea/pools/0x98446ac9f1aeec7154ccfbe15564dc31cb25bfe5</a> <br/>
            CA: 0x6dae8434644dce01d6d4b200c56c2c078bf7e639 <br/>
            
          </p> */}
            <footer className="text-white bg-opacity-0 px-4 py-5 mt-10 max-w-screen-xl mx-auto md:px-8">
                <div className="max-w-lg sm:mx-auto sm:text-center">
                    <img src={Logo} alt='doggi' className="w-48 mx-auto" />
                    <p className="leading-relaxed mt-2 text-2xl text-white">
                        Your fun, community-driven No. 1 memecoin on the revolutionary Arbitrum L2!
                    </p>
                </div>
                <ul className="items-center justify-center mt-8 space-y-5 sm:flex sm:space-x-4 sm:space-y-0 text-2xl">
                    {
                        footerNavs.map((item, idx) => (
                            <li className="text-sky-500 hover:text-sky-600">
                                <a key={idx} href={item.href}>
                                    {item.name}
                                </a>
                            </li>
                        ))
                    }
                </ul>
                <div className="mt-8 items-center justify-between sm:flex flex flex-col">



                    <div className="m-4 ">
                        <ul className="flex items-center space-x-4 ">
                            <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                                <a target="_blank" href="https://x.com/ArbDoggi">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
<path fill="#212121" fill-rule="evenodd" d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z" clip-rule="evenodd"></path><path fill="#fff" d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"></path><polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"></polygon><polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"></polygon>
</svg>
                                    </a>
                            </li>
                            <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                                <a target="_blank" href="https://opensea.io/collection/doggi-15/">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
<circle cx="24" cy="24" r="20" fill="#1565c0"></circle><path fill="#fff" d="M12,27h7c0,1.491,0.515,2,2,2h7c0.84,0,2.157-0.287,2.707-1.316l6.178-1.798L37,27.564	c-1.074,0.407-1.979,0.648-3.612,3.327C31.138,34.218,30.783,34,28.058,34H19C14.92,34,12,31,12,27L12,27z"></path><path fill="#fff" d="M19.172,16.034C22.759,20.517,19,25,19,25h-6L19.172,16.034z"></path><path fill="#fff" d="M21,27h7c0,0,8.207-5.448-1.655-11.724C23.655,14.379,20,14,20,14S26,21,21,27z"></path><path fill="#fff" d="M25,12L25,12c-0.552,0-1,0.448-1,1v17h2V13C26,12.448,25.552,12,25,12z"></path>
</svg>
                                    </a>
                            </li>

                        </ul>
                    </div>

                    <div className="mt-4 sm:mt-0">
                        &copy; {date} Doggi All rights reserved.
                    </div>

                </div>
                <style jsx>{`
                .svg-icon path,
                .svg-icon polygon,
                .svg-icon rect {
                    fill: currentColor;
                }
            `}</style>
            </footer>

        </div>
    )
}

export default Footer